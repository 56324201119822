import React from "react";
import classNames from "classnames";
import loadable from "@loadable/component";
import { Image, Text, Container, Button, Icon } from "@atoms";
import t from "@utils/t";
import useLang from "@hooks/useLang";

const Hero = loadable(() => import("@organisms/Hero"));

const PageBuilder = ({ blocks }) => {
  const lang = useLang();
  return (
    <>
      {blocks.map((b, i) => {
        const { type, uid } = b;
        switch (type) {
          case "hero":
            return <Hero key={uid} {...b} />;
          case "stats":
            return (
              <Container frame className="my-6">
                <div className="mt-3 flex flex-wrap items-center justify-between gap-10 border-b-2 border-white pb-3 leading-tight md:flex-nowrap lg:mt-6 lg:pb-6">
                  <Text
                    variant={lang === "fr" ? "fr-metric" : "metric"}
                    className={classNames("whitespace-nowrap", {
                      "order-2": i % 2 === 0,
                    })}
                  >
                    {b.stat}
                  </Text>
                  <Text
                    variant="metricLabel"
                    className={classNames("uppercase", {
                      "order-1": i % 2 === 0,
                    })}
                  >
                    {b.label}
                  </Text>
                </div>
              </Container>
            );
          case "imageCopy":
            return (
              <Container frame className="my-14">
                <div className="flex flex-wrap flex-gap-10">
                  <div className="w-full md:w-1/2">
                    <Image
                      image={b.image}
                      caption={`${t("Photo courtest of", lang)} ${
                        b.image.attribution
                      }`}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <Text className="prose">{b.copy}</Text>
                  </div>
                </div>
              </Container>
            );
          case "leadCopy":
            return (
              <div className="my-14">
                <Container frame>
                  <Text className="font-magilio text-4xl text-white md:text-6xl">
                    {b.copy}
                  </Text>
                </Container>
              </div>
            );
          case "image":
            return (
              <div className="my-14">
                <Container frame>
                  <Image
                    image={b.image}
                    caption={`${t("Photo courtesy of", lang)} ${
                      b.image.attribution
                    }`}
                  />
                </Container>
              </div>
            );
          case "imageGrid":
            return (
              <Container frame className="my-14">
                <div className="grid w-full grid-cols-2 gap-6">
                  {b.images.map((image, ii) => (
                    <div className={classNames({ "col-span-2": ii % 3 === 0 })}>
                      <Image
                        image={image}
                        caption={
                          b.captionOverride
                            ? null
                            : `${t("Photo courtesy of", lang)} ${
                                b.image.attribution
                              }`
                        }
                      />
                    </div>
                  ))}
                </div>
                {b.captionOverride && (
                  <Text variant="caption" className="mt-6">
                    {b.captionOverride}
                  </Text>
                )}
              </Container>
            );
          case "copyButtons":
            return (
              <Container frame className="my-14">
                <div className="flex flex-wrap flex-gap-10">
                  <div className="w-full md:w-1/2">
                    <Text className="prose">{b.copy}</Text>
                  </div>
                  <div className="flex w-full flex-wrap items-center justify-center gap-6 text-center md:w-1/2">
                    <Text className="w-full max-w-xs font-magilio text-4xl md:-mb-10">
                      {b.heading}
                    </Text>
                    {b.buttons &&
                      b.buttons.map(button => (
                        <Button color="black" to={button.button.url}>
                          {button.button.text}{" "}
                          <Icon
                            name="arrow"
                            className="ml-1 mt-px -mb-px h-3 w-3 rotate-90"
                            fitHeight
                          />
                        </Button>
                      ))}
                  </div>
                </div>
              </Container>
            );
          case "list":
            return (
              <Container frame>
                <Text
                  tag="h3"
                  className="my-8 w-full max-w-xs text-4xl font-bold uppercase"
                >
                  {b.heading}
                </Text>
                <ul
                  className={classNames("font-bold", {
                    "sm:columns-2": b.columns === "2",
                    "sm:columns-2 md:columns-3": b.columns === "3",
                  })}
                >
                  {b.listItems.map(item => (
                    <li className="mb-2">{item.item}</li>
                  ))}
                </ul>
              </Container>
            );
          default:
            return <div>{type}</div>;
        }
      })}
    </>
  );
};
export default PageBuilder;
